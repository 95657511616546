
// import React from "react";
// import { styled } from "@mui/material/styles";
// import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import './CarouselOne.css'

// // Images
// import AcquisitionFunding from "../../../assets/images/carousel_one/Acquisition Funding .png";
// import AngelInvestment from "../../../assets/images/carousel_one/Angel Investment.png";
// import CorporateFinance from "../../../assets/images/carousel_one/Corporate Finance.png";
// import PrivateEquityFunding from "../../../assets/images/carousel_one/Private Equity Funding .png";
// import ProjectFinance from "../../../assets/images/carousel_one/Project Finance.png";
// import PromoterFunding from "../../../assets/images/carousel_one/Promoter Funding.png";
// import SMELoans from "../../../assets/images/carousel_one/SME Loans.png";
// import VentureCapital from "../../../assets/images/carousel_one/Venture Capital.png";
// import WorkingCapital from "../../../assets/images/carousel_one/Working Capital.png";

// // Carousel options
// const options = {
//   loop: true,
//   margin: 6,
//   nav: true,
//   responsive: {
//     0: {
//       items: 1.5,
//     },
//     600: {
//       items: 2,
//     },
//     1000: {
//       items: 5,
//     },
//     1080: {
//       items: 7,
//     },
//   },
// };

// // Data array
// const cardData = [
//   { img: AcquisitionFunding, title: "Acquisition Funding" },
//   { img: AngelInvestment, title: "Angel Investment" },
//   { img: CorporateFinance, title: "Corporate Finance" },
//   { img: PrivateEquityFunding, title: "Private Equity Funding" },
//   { img: ProjectFinance, title: "Project Finance" },
//   { img: PromoterFunding, title: "Promoter Funding" },
//   { img: SMELoans, title: "SME Loans" },
//   { img: VentureCapital, title: "Venture Capital" },
//   { img: WorkingCapital, title: "Working Capital" },
// ];

// const CarouselOne = () => {
//   return (
//     <div
//       style={{
//         width: "100%",
//         height: "100%",
//         backgroundColor: "#EEF5F9",
//         overflow: "hidden",
//         fontFamily: "Daikon-regular",
//         textAlign: "center",
//       }}
//     >
//       <OwlCarousel
//         className="owl-theme"
//         autoplay={true}
//         autoplayTimeout={2000}
//         nav
//         {...options}
//       >
//         {cardData.map((card, index) => (
//           <Card
//             key={index}
//             className="item"
//             sx={{
//               maxWidth: 230,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "14px",
//               // marginLeft: "12px",
//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={card.img}
//                     alt={card.title}
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong>{card.title}</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//         ))}
//       </OwlCarousel>
//     </div>
//   );
// };

// export default CarouselOne;




import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Card, Container, Typography } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './CarouselOne.css'
import "../MainBody.css";

//  Images
import AcquisitionFunding from "../../../assets/images/carousel_one/Acquisition Funding .png";
import AngelInvestment from "../../../assets/images/carousel_one/Angel Investment.png";
import CorporateFinance from "../../../assets/images/carousel_one/Corporate Finance.png";
import PrivateEquityFunding from "../../../assets/images/carousel_one/Private Equity Funding .png";
import ProjectFinance from "../../../assets/images/carousel_one/Project Finance.png";
import PromoterFunding from "../../../assets/images/carousel_one/Promoter Funding.png";
import SMELoans from "../../../assets/images/carousel_one/SME Loans.png";
import VentureCapital from "../../../assets/images/carousel_one/Venture Capital.png";
import WorkingCapital from "../../../assets/images/carousel_one/Working Capital.png";


// const options = {
//   loop: true,
//   margin: 14,
//   nav: true,
//   responsive: {
//     0: { items: 1.5 },
//     600: { items: 2 },
//     1000: { items: 5 },
//     1080: { items: 6},
//   },
// };



//  Data array
const cardData = [
  { img: AcquisitionFunding, title: "Acquisition Funding" },
  { img: AngelInvestment, title: "Angel Investment" },
  { img: CorporateFinance, title: "Corporate Finance" },
  { img: PrivateEquityFunding, title: "Private Equity Funding" },
  { img: ProjectFinance, title: "Project Finance" },
  { img: PromoterFunding, title: "Promoter Funding" },
  { img: SMELoans, title: "SME Loans" },
  { img: VentureCapital, title: "Venture Capital" },
  { img: WorkingCapital, title: "Working Capital" },
];

// export default function BodyInside() {
//   return (
//     <Box
//       sx={{
//         width: "100%",
//         height: "100%",
//         backgroundColor: "#EEF5F9",
//         overflow: "hidden",
//         paddingTop: '22px',
//         fontFamily: "Daikon-regular",
//         textAlign: "center",
//       }}
//     >
//       <OwlCarousel
//         className="owl-theme"
//         loop
//         autoplay
//         margin={14}  // Keeping the same gap value
//         autoplayTimeout={3000}
//         nav
//         {...options}
//         style={{ textAlign: "center" }}
//       >
//         {cardData.map((card, index) => (
//           <Card
//             key={index}
//             className="item"
//             sx={{
//               boxShadow: 5,
//               borderRadius: "12px",
//               marginTop: 2,
//               marginBottom: 2,
//               // Set a fixed width for the card to reduce its size
//               width: 'auto',
//               maxWidth: '250px', // Set a max width to shrink the card size
//               padding: 1, // Optional, to adjust internal padding
//             }}
//           >
//             <h4>
//               <img
//                 className="carouselDiv"
//                 src={card.img}
//                 alt={card.title}
//                 style={{
//                   width: "14vh", // Reduced image size for smaller cards
//                   margin: "auto",
//                 }}
//               />
//               <Typography
//                 sx={{
//                   marginTop: "24px",
//                   "&:hover": { color: "green", cursor: "pointer" },
//                 }}
//               >
//                 {card.title}
//               </Typography>
//             </h4>
//           </Card>
//         ))}
//       </OwlCarousel>

//       <div style={{ paddingBottom: "20px", marginTop: "18px" }}>
//         {/* Optional "View More" button */}
//       </div>
//     </Box>
//   );
// }

const options = {
  loop: true,
  nav: true,
  autoplay: true,
  autoplayTimeout: 3000,
  responsive: {
    0: { 
      items: 1.5, 
      margin: 10 // Small margin on smaller screens
    },
    600: { 
      items: 6, 
      margin: 14 // Medium margin for slightly larger screens
    },
    1000: { 
      items: 6, 
      margin: 20 // Standard margin for large screens
    },
  
     
    1080: { 
      items: 6.5, 
      margin: 20 // Keep the same gap for very large screens
    },
  
  }
};

export default function BodyInside() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#EEF5F9",
        overflow: "hidden",
        paddingTop: '22px',
        fontFamily: "Daikon-regular",
        textAlign: "center",
      }}
    >
      <OwlCarousel
        className="owl-theme"
        loop
        autoplay
        autoplayTimeout={3000}
        nav
        {...options}
        style={{ textAlign: "center" }}
      >
        {cardData.map((card, index) => (
          <Card
            key={index}
            className="item"
            sx={{
              boxShadow: 5,
              borderRadius: "12px",
              marginTop: 2,
              marginBottom: 2,
              // Adjust the card size with maxWidth for a smaller size
              width: 'auto',
              minWidth: '170px', // Ensuring the card doesn't exceed a max size
              padding: 1,
            }}
          >
            <h4>
              <img
                className="carouselDiv"
                src={card.img}
                alt={card.title}
                style={{
                  width: "14vh", // Reduced image size for smaller cards
                  margin: "auto",
                }}
              />
              <Typography
                sx={{
                  marginTop: "24px",
                  "&:hover": { color: "green", cursor: "pointer" },
                }}
              >
                {card.title}
              </Typography>
            </h4>
          </Card>
        ))}
      </OwlCarousel>

      <div style={{ paddingBottom: "20px", marginTop: "18px" }}>
        {/* Optional "View More" button */}
      </div>
    </Box>
  );
}
