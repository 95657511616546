import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography, Card, Divider, Carousel, List } from "antd";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import {
  PieChartOutlined,
  TeamOutlined,
  BarChartOutlined,
  RocketOutlined,
  BookOutlined, LeftOutlined, RightOutlined,
  ToolOutlined, ArrowRightOutlined, CheckCircleOutlined, UserOutlined, BankOutlined, MessageOutlined,
  MoneyCollectOutlined, BulbOutlined, FundOutlined, ClusterOutlined
} from '@ant-design/icons';
import { Box, } from "@mui/material";
import "./Shark.css";

// Importing the image files
import whyChooseImage1 from "../../assets/images/Be-aShark-Launch-day.jpg";
import whyChooseImage7 from "../../assets/images/Be-a-shark-launch.jpg";
import whyChooseImage2 from "../../assets/images/Pune-event.JPG";
import whyChooseImage8 from "../../assets/images/Chandigarh-Event.JPG";
import whyChooseImage9 from "../../assets/images/Delhi-Event-2nd-edition.JPG";
import whyChooseImage3 from "../../assets/images/Nagesh-Jakhar-Ashneer.jpg";
import whyChooseImage4 from "../../assets/images/Delhi-Event.jpg";
import whyChooseImage5 from "../../assets/images/Launch-Day.jpg";
import whyChooseImage10 from "../../assets/images/WhatsApp-1.jpeg";
import whyChooseImage12 from "../../assets/images/WhatsApp-2.jpeg";
import whyChooseImage13 from "../../assets/images/WhatsApp-3.jpeg";
import whyChooseImage11 from "../../assets/images/Sachin-K-sheoran.jpg";
import blog from "../../assets/images/blog-3.jpg";
import community from "../../assets/images/skyscrapers-from-low-angle-view2.webp";
import beshark from "../../assets/images/bearded-male.jpg";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const Shark = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const handleMouseOver = (event) => {
    event.target.style.backgroundColor = "darkgreen";
  };

  const handleMouseOut = (event) => {
    event.target.style.backgroundColor = "green";
  };

  const carouselRef = React.useRef();

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };

  const sliderData = [
    { image: whyChooseImage1, title: "Be a Shark launch day with OG Shark", subheading: "Building Fortunes and Creating Legacies" },
    { image: whyChooseImage2, title: "Women Empowering", subheading: "Empowering Women, Fueling Innovation" },
    { image: whyChooseImage3, title: "Ashneer Grover with Nagesh Jakhar", subheading: "Visionaries in Action: Driving Business Growth & Smart Investments" },
    { image: whyChooseImage4, title: "Delhi Event with Investors", subheading: "Connecting Investors & Entrepreneurs in the Heart of Delhi" },
    { image: whyChooseImage5, title: "Be a Shark Team", subheading: "Fearless Mindset, Unstoppable Success" },
    { image: whyChooseImage7, title: "Be a Shark Leaders", subheading: "Lead with Power, Dominate with Vision" },
    { image: whyChooseImage8, title: "Chandigarh Event with Investors", subheading: "Connecting Visionaries, Empowering Investments" },
    { image: whyChooseImage9, title: "Delhi Event with Investors (2nd Edition)", subheading: "Delhi Investors Meet 2.0 – Bigger, Bolder, Smarter" },
    { image: whyChooseImage10, title: "Partnership with Investors", subheading: "Stronger Together, Investing in Success" },
    { image: whyChooseImage13, title: "Partnership with Investors", subheading: "Stronger Together, Investing in Success" },
    { image: whyChooseImage11, title: "Sachin K Sheron", subheading: "Leading the Be a Shark Revolution" },
  ];

  const categories = [
    { icon: <MoneyCollectOutlined style={{ fontSize: '40px', color: '#2DBA71' }} />, title: "Angel Investors", description: "Seeking high-return opportunities." },
    { icon: <UserOutlined style={{ fontSize: '40px', color: '#2DBA71' }} />, title: "Corporate Leaders", description: "Offering strategic mentorship and insights." },
    { icon: <RocketOutlined style={{ fontSize: '40px', color: '#2DBA71' }} />, title: "Founders", description: "Looking for funding, scalability, and networking." },
    { icon: <TeamOutlined style={{ fontSize: '40px', color: '#2DBA71' }} />, title: "Incubators & Accelerators", description: "Supporting investment readiness and scaling." },
    { icon: <BankOutlined style={{ fontSize: '40px', color: '#2DBA71' }} />, title: "Banks & Financial Institutions", description: "Providing financial support and funding." },
    { icon: <MessageOutlined style={{ fontSize: '40px', color: '#2DBA71' }} />, title: "Media & Influencers", description: "Amplifying SME and startup success stories." },
    { icon: <BulbOutlined style={{ fontSize: '40px', color: '#2DBA71' }} />, title: "Aspiring Investors", description: "Exploring SME investments." },
  ];

  const features = [
    {
      icon: <RocketOutlined style={{ fontSize: '40px' }} />,
      title: 'Investment-Ready SMEs & Startups',
      description: 'Only high-growth potential businesses are selected for the platform.',
    },
    {
      icon: <ClusterOutlined style={{ fontSize: '40px' }} />,
      title: 'Early Access to Promising Ventures',
      description: 'Identify and invest in India’s most promising SMEs and startups.',
    },
    {
      icon: <TeamOutlined style={{ fontSize: '40px' }} />,
      title: 'Premium Business Networking',
      description: 'Connect with industry leaders and market pioneers.',
    },
    {
      icon: <BarChartOutlined style={{ fontSize: '40px' }} />,
      title: 'Data-Driven Investment Insights',
      description: 'Make informed decisions backed by deep market research.',
    },
    {
      icon: <FundOutlined style={{ fontSize: '40px' }} />,
      title: 'Flexible Investment Models',
      description: 'Start small, co-invest, or invest significantly.',
    },
  ];

  const goToSharkForm = () => {
    window.scrollTo(0, 0);
    navigate('/be-a-shark-form');
  };

  return (
    <div>
      <Navbar />
      <div style={{ position: "relative", overflow: "hidden" }}>
        <Carousel
          ref={carouselRef}
          autoplay
          autoplaySpeed={2000}
          effect="fade"
          dotPosition="bottom"
          style={{ height: "700px" }}
        >
          {sliderData.map((item, index) => (
            <div key={index}>
              <div
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center top",
                  height: "700px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                  }}
                ></div>
                <div
                  style={{
                    position: "relative",
                    textAlign: "center",
                    zIndex: 2,
                    marginTop: "150px",
                  }}
                >
                  <Title
                    level={2}
                    style={{
                      color: "#fff",
                      fontSize: "36px",
                      fontWeight: "bold",
                      textShadow: "2px 2px 8px rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    {item.title}
                  </Title>
                  <Paragraph
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      textShadow: "2px 2px 8px rgba(0, 0, 0, 0.8)",
                      marginTop: "20px",
                    }}
                  >
                    {item.subheading}
                  </Paragraph>
                </div>
              </div>
            </div>
          ))}
        </Carousel>

        <Button
          shape="circle"
          icon={<LeftOutlined />}
          onClick={handlePrev}
          style={{
            position: "absolute",
            top: "50%",
            left: "20px",
            transform: "translateY(-50%)",
            zIndex: 10,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            border: "none",
          }}
        />

        <Button
          shape="circle"
          icon={<RightOutlined />}
          onClick={handleNext}
          style={{
            position: "absolute",
            top: "50%",
            right: "20px",
            transform: "translateY(-50%)",
            zIndex: 10,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            border: "none",
          }}
        />
      </div>

      <div
        style={{
          padding: "40px 20px",
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <Title
          level={1}
          style={{
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#333",
            fontSize: "36px",
          }}
        >
          Be A Shark – Invest. Network. Lead.
        </Title>

        <Paragraph
          style={{
            fontSize: "16px",
            color: "#555",
            marginBottom: "30px",
          }}
        >
          Investing isn't just limited to Shark Tank—discover how real
          investors spot opportunities, structure deals, <br></br>and build successful businesses with Be
          A Shark.
        </Paragraph>

        <Divider style={{ marginBottom: "30px" }} />

        <Row gutter={[16, 16]} style={{ marginBottom: "30px", marginLeft: "94px", marginRight: "119px" }} >
          <Col xs={24} sm={8} md={8}>
            <CheckCircleOutlined style={{ fontSize: "24px", color: "#1890ff", marginBottom: "10px" }} />
            <Paragraph style={{ fontSize: "16px", color: "#333" }}>
              Invest in High-Growth SMEs
            </Paragraph>
          </Col>
          <Col xs={24} sm={8} md={8}>
            <CheckCircleOutlined style={{ fontSize: "24px", color: "#1890ff", marginBottom: "10px" }} />
            <Paragraph style={{ fontSize: "16px", color: "#333" }}>
              Network with Elite Investors
            </Paragraph>
          </Col>
          <Col xs={24} sm={8} md={8}>
            <CheckCircleOutlined style={{ fontSize: "24px", color: "#1890ff", marginBottom: "10px" }} />
            <Paragraph style={{ fontSize: "16px", color: "#333" }}>
              Scale Businesses, Maximize Returns
            </Paragraph>
          </Col>
        </Row>
      </div>

      <div className="about-section" style={{
        position: "relative",
        padding: "40px 20px",
        backgroundImage: `url(${whyChooseImage7})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        color: "#fff",
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
        minHeight: "25vh",
        display: "flex",
        alignItems: "center",
        
        justifyContent: "center"
      }}>
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1
        }}></div>

        <div style={{
          position: "relative",
          zIndex: 2,
          maxWidth: "800px",
          textAlign: "center"
        }}>
          <Title level={2} className="about-title" style={{
            color: "#fff",
            fontSize: "36px",
            fontWeight: "bold",
            marginBottom: "20px"
          }}>
            About Be A Shark
          </Title>
          <Divider style={{
            borderColor: "#fff",
            width: "60px",
            margin: "20px auto",
            backgroundColor: "#fff"
          }} />
          <Row gutter={[32, 32]} justify="center" align="middle">
            <Col xs={24} md={24}>
              <Paragraph className="about-description" style={{
                fontSize: "18px",
                lineHeight: "1.8",
                textAlign: "center",
                marginBottom: "20px"
              }}>
                Be A Shark is a strategic investment and networking platform by AQT Direct,
                designed to connect high-growth SMEs and startups with visionary investors.
                This initiative offers a structured approach to discovering, evaluating,
                and investing in disruptive businesses across multiple industries.
              </Paragraph>
              <Paragraph className="about-description" style={{
                fontSize: "18px",
                lineHeight: "1.8",
                textAlign: "center"
              }}>
                Be A Shark creates an ecosystem where investors, corporate leaders, industry
                pioneers, and SME founders collaborate to unlock new business opportunities,
                foster innovation, and build scalable success stories.
              </Paragraph>
            </Col>
          </Row>
        </div>
      </div>

      <div className="why-choose-section" >
        <Title
          level={2}
          style={{
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Why Choose Be A Shark
        </Title>
        <Box width={150} height={3} bgcolor="#0f766e" mx="auto" mt={1} style={{ marginBottom: '30px' }} />
        <Row gutter={[24, 24]} justify="center" style={{ marginTop: "20px", marginLeft: "77px", marginRight: "63px" }}>
          {[
            {
              icon: <PieChartOutlined style={{ fontSize: "48px" }} />,
              title: "Curated SME & Startup Investments",
              description: "Access a pre-vetted portfolio of high-potential SMEs and startups across various sectors.",
            },
            {
              icon: <TeamOutlined style={{ fontSize: "48px" }} />,
              title: "Elite Business Networking",
              description: "Connect with investors, corporate leaders, and market disruptors in a structured environment.",
            },
            {
              icon: <BarChartOutlined style={{ fontSize: "48px" }} />,
              title: "Data-Driven Investment Decisions",
              description: "Leverage expert insights, financial analytics, and structured deal-making platforms for informed investments.",
            },
            {
              icon: <RocketOutlined style={{ fontSize: "48px" }} />,
              title: "Scale Businesses, Maximize Returns",
              description: "Support SMEs and startups with strong market traction and high-growth potential.",
            },
            {
              icon: <BookOutlined style={{ fontSize: "48px" }} />,
              title: "Industry Access & Thought Leadership",
              description: "Gain visibility through expert panels, masterclasses, and exclusive investor circles.",
            },
            {
              icon: <ToolOutlined style={{ fontSize: "48px" }} />,
              title: "Post-Investment Growth & Mentorship",
              description: "Beyond funding, benefit from strategic partnerships, mentorship, and media amplification.",
            },
          ].map((item, index) => (
            <Col xs={24} sm={12} md={8} key={index}>
              <div
                style={{
                  padding: "20px",
                  borderRadius: "12px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-8px)";
                  e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
                }}
              >
                <div style={{ marginBottom: "16px" }}>{item.icon}</div>
                <Title level={4} style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
                  {item.title}
                </Title>
                <Paragraph style={{ fontSize: "14px", color: "#666", marginTop: "10px" }}>
                  {item.description}
                </Paragraph>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <div
        className="agenda-slide"
        style={{
          width: "100%",
          padding: "40px 20px",
          backgroundColor: "#353d4e",
          color: "white",
          margin: "0px",
          boxSizing: "border-box",
        }}
      >
        <div
          className="header"
          style={{
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          <h1
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              color: "white",
              marginBottom: "10px",
            }}
          >
            How It Works
          </h1>
          <p style={{ fontSize: "18px", color: "#a0aec0" }}>
            Discover the seamless process of investment and growth.
          </p>
        </div>

        <Row
          gutter={[40, 40]}
          align="middle"
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0 auto",
            maxWidth: "1200px",
          }}
        >
          <Col xs={24} sm={24} md={10} lg={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={blog}
                alt="How It Works"
                style={{
                  height: "500px",
                  marginLeft: "360px"
                }}
              />
            </div>
          </Col>

          <Col xs={24} sm={24} md={14} lg={16}>
            <div
              className="text-content"
              style={{
                fontSize: "16px",
                lineHeight: "1.8",
                paddingLeft: "51px"
              }}
            >
              <ul
                style={{
                  listStyleType: "none",
                  display: isMobile ? "100px" : "block", // Hide on mobile
                }}
              >
                {[
                  {
                    title: "Investor Engagement & Knowledge Sharing",
                    items: [
                      "Participate in exclusive investor meetups with top business leaders.",
                      "Access industry reports, strategic analysis, and market trends.",
                    ],
                  },
                  {
                    title: "Live SME & Startup Pitches & Deal Structuring",
                    items: [
                      "Direct access to SME and startup pitches and real-time evaluations.",
                      "Flexible investment models, including equity, revenue-sharing, and co-investment.",
                    ],
                  },
                  {
                    title: "Post-Investment Support & Scaling",
                    items: [
                      "Ongoing mentorship and growth strategy support for funded SMEs and startups.",
                      "Brand visibility and media coverage to amplify success stories.",
                    ],
                  },
                ].map((section, index) => (
                  <li key={index} style={{ marginBottom: "30px" }}>
                    <Card
                      hoverable
                      style={{
                        backgroundColor: "#2d3748",
                        border: "none",
                        borderRadius: "10px",
                        transition: "transform 0.3s ease",
                      }}
                      bodyStyle={{ padding: "20px" }}
                    >
                      <strong style={{ fontSize: "18px", color: "#fff" }}>
                        {section.title}
                      </strong>
                      <ul
                        style={{
                          paddingLeft: "20px",
                          listStyleType: "disc",
                          marginTop: "10px",
                        }}
                      >
                        {section.items.map((item, i) => (
                          <li key={i} style={{ color: "#a0aec0" }}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Card>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      <div style={{ padding: "40px 10px", backgroundColor: "#eeecec" }}>
        <Title
          level={2}
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontWeight: "bold",
            color: "#333",
            fontSize: "28px",
          }}
        >
          Who Should Join
        </Title>
        <Box width={130} height={4} bgcolor="#2DBA71" mx="auto" mt={1} style={{ marginBottom: '30px' }} />
        <Row gutter={[10, 10]} justify="center" style={{ marginLeft: "27px", marginRight: "43px" }}>
          {categories.map((item, index) => (
            <Col key={index} xs={24} sm={12} md={6} lg={3}>
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  animation: `fadeIn 1s ease-out ${index * 0.2}s`,
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "translateY(0)")}
              >
                <div style={{ color: "#2DBA71", fontSize: "20px" }}>{item.icon}</div>
                <Title level={4} style={{ fontSize: "14px", marginBottom: "5px", color: "#000" }}>
                  {item.title}
                </Title>
                <Text style={{ fontSize: "12px", color: "#333" }}>{item.description}</Text>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <div>
        <div
          style={{
            padding: isMobile ? "20px 20px" : "80px 20px",
            backgroundImage: `url(${community})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            textAlign: "center",
            color: "#fff",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            }}
          ></div>

          <div style={{ position: "relative", zIndex: 2, width: "100%" }}>
            <Title
              level={1}
              style={{
                fontWeight: "bold",
                marginBottom: isMobile ? "5px" : "20px", // Adjust margin for mobile
                color: "#fff",
                fontSize: isMobile ? "24px" : "36px", // Adjust font size for mobile
                padding: isMobile ? "0 10px" : "0", // Add padding for mobile
              }}
            >
              Why Invest with Be A Shark
            </Title>
            <Divider
              style={{
                borderColor: "black",
                width: "60px",
                margin: isMobile ? "10px auto" : "15px auto", // Adjust margin for mobile
                display: isMobile ? "none" : "block", // Hide divider on mobile
              }}
            />
            <Row
              gutter={[20, 20]} // Reduce gutter for mobile
              justify="center"
              style={{
                maxWidth: "1158px",
                margin: "0 auto",
                padding: isMobile ? "0 10px" : "0", // Add padding for mobile
              }}
            >
              {features.map((feature, index) => (
                <Col
                  xs={24}
                  sm={24} // Full width on mobile
                  md={12}
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "10px" : "20px", // Adjust margin for mobile
                    flexDirection: isMobile ? "column" : "row", // Stack items vertically on mobile
                    textAlign: isMobile ? "center" : "left", // Center text on mobile
                  }}
                >
                  <div style={{ marginRight: isMobile ? "0" : "20px", color: "#fff" }}>
                    {feature.icon}
                  </div>
                  <div>
                    <Title
                      level={4}
                      style={{
                        fontSize: isMobile ? "16px" : "18px", // Adjust font size for mobile
                        color: "#fff",
                        marginBottom: isMobile ? "5px" : "10px", // Adjust margin for mobile
                      }}
                    >
                      {feature.title}
                    </Title>
                    <Text
                      style={{
                        fontSize: isMobile ? "12px" : "14px", // Adjust font size for mobile
                        color: "#ddd",
                      }}
                    >
                      {feature.description}
                    </Text>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>



      <div style={{ padding: "60px 20px", backgroundColor: "#f0f0f0", textAlign: "center" }}>
        <Title
          level={2}
          style={{ color: "black", marginBottom: "20px", fontWeight: "bold" }}
        >
          Join Be A Shark Today
        </Title>
        <Paragraph style={{ fontSize: "16px", color: "black", marginBottom: "30px", maxWidth: "800px", margin: "0 auto" }}>
          Be A Shark is redefining SME and startup investing by creating a structured, transparent, and high-impact funding ecosystem. Whether you are an experienced investor or new to SME and startup funding, this platform provides the right opportunities, resources, and networks to drive success.
        </Paragraph>
        <button
          style={{
            width: "120px",
            backgroundColor: "green",
            color: "white",
            padding: "10px",
            borderRadius: "35px",
            border: "none",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            marginTop: "16px",
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={goToSharkForm}
        >
          Join Now
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default Shark;