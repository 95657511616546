// import React from "react";
// import Navbar from "../../components/navbar/index";
// import Footer from "../../components/Footer/Footer";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import business from "../../assets/images/business.jpg";
// import "./Common.css";
// import { Container, Grid } from "@mui/material";

// export default function Business() {
//   return (
//     <>
//       <Navbar />
//       <Box>
//         <img
//           style={{
//             width: "100%",
//             justifyContent: "center",
//             margin: "auto",
//             display: "block",
//             height: "20rem",

//           }}
//           src={business}
//           alt="business"
//         />
//       </Box>
//       <Typography
//         variant="h5"
//         sx={{
//           textAlign: "center",
//           fontWeight: 600,
//           fontFamily: "Daikon-regular",
//           marginTop: "12px",
//         }}
//       >
//         Secure Returns with Proven, Established Companies
//       </Typography>
//       <Container>
//         <Box
//           sx={{
//             textAlign: "start",
//             fontFamily: "Daikon-regular",
//           }}
//         >
//           <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
//             For investors seeking more stability, established businesses offer a
//             proven track record, steady cash flow, and predictable returns.
//             These companies have weathered market challenges and demonstrated
//             their ability to generate sustainable growth over time. At AQT, we
//             offer curated investment opportunities in businesses with strong
//             financial fundamentals, robust market positioning, and low-risk
//             profiles.
//           </Typography>
//           <Box sx={{ paddingTop: "1rem" }}>
//             <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
//               Investing in established businesses allows you to balance your
//               portfolio with less volatility, making them ideal for risk-averse
//               investors or those looking to preserve capital while generating
//               income.
//             </Typography>
//           </Box>
//         </Box>
//       </Container>
//       <Grid
//         style={{ marginTop: "12px" }}
//         container
//         spacing={2}
//         columns={{ xs: 4, sm: 8, md: 12 }}
//       >
//         <Grid item xs={4} sm={8} md={6}>
//           <Box className="commoncardInside">
//             <h1 className="card-heading">Why Established Businesses?</h1>
//             <ul className="card-list">
//               <li>
//                 <strong>Lower Risk: </strong> Established businesses have
//                 survived the early stages of growth and have proven their
//                 ability to deliver steady returns.
//               </li>
//               <li>
//                 <strong>Reliable Cash Flow: </strong>These companies typically
//                 generate consistent cash flow from operations, providing
//                 investors with a more predictable income stream.
//               </li>
//               <li>
//                 <strong>Proven Business Models: </strong>With years of
//                 operational history, these companies offer less uncertainty
//                 regarding their market position and long-term sustainability.
//               </li>
//               <li>
//                 <strong>Opportunities for Expansion: </strong> Many established
//                 businesses still have room for growth, particularly through
//                 geographic expansion, new product lines, or acquisitions.
//               </li>
//             </ul>
//           </Box>
//         </Grid>
//         <Grid item xs={4} sm={8} md={6}>
//           <Box className="commoncardInside">
//             <h1 className="card-heading">
//               How We Select Established Businesses
//             </h1>
//             <ul className="card-list">
//               <li>
//                 <strong>Financial Performance: </strong>We analyze historical
//                 financial data, including revenue growth, profitability, and
//                 cash flow stability.
//               </li>
//               <li>
//                 <strong>Market Position: </strong> We assess the company’s
//                 market share and competitive advantage within its industry to
//                 ensure long-term growth prospects.
//               </li>
//               <li>
//                 <strong>Risk Management: </strong>We evaluate the company’s
//                 operational risks, including leadership stability, governance,
//                 and regulatory compliance, to minimize investment risk.
//               </li>
//             </ul>
//           </Box>
//         </Grid>
//       </Grid>
//       <Footer />
//     </>
//   );
// }

import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import business from "../../assets/images/carousel_one/business.jpg";
import { Container, Grid } from "@mui/material";
import "./Common.css";

export default function Business() {
  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <Box sx={{ position: "relative", mb: 4 }}>
        <img
          src={business}
          alt="aboutus"
          style={{
            width: "100%",
            height: "24rem",
            objectFit: "cover",
            display: "block",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            textAlign: "center",
            // background: "rgba(0, 0, 0, 0.5)",
            padding: "1rem 2rem",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Secure Returns with Proven, Established Companies
          </Typography>
        </Box>
      </Box>
      {/* <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          fontFamily: "Daikon-regular",
          marginTop: "12px",
          color: "#333",
        }}
      >
        Secure Returns with Proven, Established Companies
      </Typography> */}

      {/* Description Section */}
      <Container sx={{ marginTop: "20px", marginBottom: "40px" }}>
        <Box
          sx={{
            textAlign: "start",
            fontFamily: "Daikon-regular",
            color: "#555",
            lineHeight: "1.8",
          }}
        >
          <Typography variant="body1">
            For investors seeking more stability, established businesses offer a
            proven track record, steady cash flow, and predictable returns.
            These companies have weathered market challenges and demonstrated
            their ability to generate sustainable growth over time. At AQT, we
            offer curated investment opportunities in businesses with strong
            financial fundamentals, robust market positioning, and low-risk
            profiles.
          </Typography>
          <Box sx={{ paddingTop: "1rem" }}>
            <Typography variant="body1">
              Investing in established businesses allows you to balance your
              portfolio with less volatility, making them ideal for risk-averse
              investors or those looking to preserve capital while generating
              income.
            </Typography>
          </Box>
        </Box>
      </Container>

      {/* Grid Section */}
      <Container sx={{ marginBottom: "60px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box
              className="card-hover"
              sx={{
                backgroundColor: "#f9f9f9",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  marginBottom: "16px",
                  color: "#333",
                }}
              >
                Why Established Businesses?
              </Typography>
              <ul className="card-list" style={{ margin: 0, padding: "0 0 0 16px" }}>
                <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                  <strong>Lower Risk:</strong> Established businesses have
                  survived the early stages of growth and proven their ability to
                  deliver steady returns.
                </li>
                <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                  <strong>Reliable Cash Flow:</strong> These companies typically
                  generate consistent cash flow from operations, providing
                  investors with a more predictable income stream.
                </li>
                <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                  <strong>Proven Business Models:</strong> With years of
                  operational history, these companies offer less uncertainty
                  regarding their market position and long-term sustainability.
                </li>
                <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                  <strong>Opportunities for Expansion:</strong> Many established
                  businesses still have room for growth, particularly through
                  geographic expansion, new product lines, or acquisitions.
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              className="card-hover"
              sx={{
                backgroundColor: "#f9f9f9",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  marginBottom: "16px",
                  color: "#333",
                }}
              >
                How We Select Established Businesses
              </Typography>
              <ul className="card-list" style={{ margin: 0, padding: "0 0 0 16px" }}>
                <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                  <strong>Financial Performance:</strong> We analyze historical
                  financial data, including revenue growth, profitability, and
                  cash flow stability.
                </li>
                <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                  <strong>Market Position:</strong> We assess the company’s
                  market share and competitive advantage within its industry to
                  ensure long-term growth prospects.
                </li>
                <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                  <strong>Risk Management:</strong> We evaluate the company’s
                  operational risks, including leadership stability, governance,
                  and regulatory compliance, to minimize investment risk.
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}
