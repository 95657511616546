// export const api = 'http://192.168.1.24:8080/api/V1/global/company'
// export const imageApi = 'http://192.168.1.24:8080/api/V1/user/download/?title='
// export const redirect = "http://103.251.94.157:3001/login"
// export const contactapi = 'http://192.168.1.24:8080/api/V1/global/email'
// export const tableapi = 'http://192.168.1.24:8080/api/V1/global/company/sharedetails'


// export const api = 'http://192.168.1.47:8080/api/V1//global/company/'
// export const imageApi = 'http://192.168.1.47:8080/api/V1/user/download/?title='
// export const downloadapi = 'http://192.168.1.47:8080/api/V1/download/balancesheet/'


export const api = 'https://api.aqtdirect.com/api/V1/global/company'
// export const imageApi = 'http://103.251.94.157:3000/api/V1/user/download/?title='
export const imageApi = 'https://ekartrent.s3.amazonaws.com/'
export const redirect = "https://admin.aqtdirect.com/login"
export const contactapi = 'https://api.aqtdirect.com/api/V1/global/email'
export const tableapi = 'https://api.aqtdirect.com/api/V1/global/company/sharedetails'
export const downloadapi = 'https://api.aqtdirect.com/api/V1/download/balancesheet/'
export const beashark = 'https://api.aqtdirect.com/api/V1/global/be-shark'