import "./FundRaise.css";
import raisefunding from "../RaiseFunding/Raisefunding.png";
import build from "../RaiseFunding/build.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import beyondmoney from "../../assets/images/beyondmoney.jpg";
import fundraise from "../../assets/images/fundraise.jpg";
import Container from "@mui/material/Container";
import { Grid, Box, TextField } from "@mui/material";
import { Card, Row, Col, Typography } from "antd";
import strategic from "../../assets/images/Strategic.jpeg";
import Equity from "../../assets/images/Equity.jpeg";
import Investor from "../../assets/images/investor.webp";
import Compelling from "../../assets/images/Compelling.jpeg";
import image1 from "../../assets/images/stockmarket2.jpg";
import fundraiseImage from "../../assets/images/fund-raise.jpg";
import React, { useState, useEffect } from "react";

import {
  PieChartOutlined,
  SwapOutlined,
  LineChartOutlined,
  FileTextOutlined,
  DollarCircleOutlined,
  GlobalOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Meta } = Card;
const features = [
  {
    title: "Payout",
    description:
      "This is your money, you can withdraw it at any point during the course of your fundraiser.",
  },
  {
    title: "International Payment Support",
    description:
      "We accept donations in multiple currencies from anywhere in the world.",
  },
  {
    title: "Multiple People - Same Fundraiser",
    description: "Multiple people manage and fundraise for your cause.",
  },
];

// Function to get the appropriate icon based on the title
const getIconByTitle = (title) => {
  switch (title) {
    case "Payout":
      return (
        <DollarCircleOutlined
          className="feature-icon"
          style={{ fontSize: "24px", color: "#0f766e" }}
        />
      );
    case "International Payment Support":
      return (
        <GlobalOutlined
          className="feature-icon"
          style={{ fontSize: "24px", color: "#0f766e" }}
        />
      );
    case "Multiple People - Same Fundraiser":
      return (
        <TeamOutlined
          className="feature-icon"
          style={{ fontSize: "24px", color: "#0f766e" }}
        />
      );
    default:
      return null;
  }
};
const data = [
  {
    title: "Strategic Capital Planning",
    icon: <PieChartOutlined className="icon" style={{ color: "#1890ff" }} />, // Blue color
    points: [
      "We analyze your financial requirements, business model, and expansion goals.",
      "A customized fundraising strategy is developed to align with your long-term vision.",
    ],
  },
  {
    title: "Equity & Debt Structuring",
    icon: <SwapOutlined className="icon" style={{ color: "#52c41a" }} />, // Green color
    points: [
      "Get expert guidance on choosing between equity financing and debt funding.",
      "We structure deals that maintain financial stability and growth potential.",
    ],
  },
  {
    title: "Investor-Ready Financials & Valuation",
    icon: <LineChartOutlined className="icon" style={{ color: "#faad14" }} />, // Gold color
    points: [
      "Accurate business valuations to strengthen investor negotiations.",
      "Financial insights that enhance credibility and decision-making.",
    ],
  },
  {
    title: "Compelling Investor Pitch Preparation",
    icon: <FileTextOutlined className="icon" style={{ color: "#f5222d" }} />, // Red color
    points: [
      "Professionally crafted pitch decks and financial models.",
      "Data-driven presentations that captivate and convince investors.",
    ],
  },
];

const services = [
  {
    img: strategic,
    title: "Strategic Capital Planning",
    points: [
      "We analyze your financial requirements, business model, and expansion goals.",
      "A customized fundraising strategy is developed to align with your long-term vision.",
    ],
  },
  {
    img: Equity,
    title: "Equity & Debt Structuring",
    points: [
      "Get expert guidance on choosing between equity financing and debt funding.",
      "We structure deals that maintain financial stability and growth potential.",
    ],
  },
  {
    img: Investor,
    title: "Investor-Ready Financials & Valuation",
    points: [
      "Accurate business valuations to strengthen investor negotiations.",
      "Financial insights that enhance credibility and decision-making.",
    ],
  },
  {
    img: Compelling,
    title: "Compelling Investor Pitch Preparation",
    points: [
      "Professionally crafted pitch decks and financial models.",
      "Data-driven presentations that captivate and convince investors.",
    ],
  },
];

export default function FundRaise() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const dataToSubmit = {};

    formData.forEach((value, key) => {
      dataToSubmit[key] = value;
    });

    fetch("https://api.aqtdirect.com/api/V1/global/fund-raising", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(dataToSubmit),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.success) {
          navigate("/thank-you");
        } else {
          alert("Error submitting form. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to submit form. Please check your network connection.");
      });
  };

  return (
    <>
      <Navbar />
      <Container
        className="contact-main-container"
        style={{
          backgroundImage: `url(${fundraiseImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "50px 20px",
          position: "relative",
          color: "#fff",
          width: "100vw",
          maxWidth: "100%",
          backgroundColor: "#097b72",
          marginTop: "0px",
          "@media (max-width: 768px)": {
            padding: "30px 10px", // Reduce padding for smaller screens
          },
        }}
      >
        {/* Overlay for better readability */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
            zIndex: 1,
          }}
        />

        <Grid container spacing={4} style={{ position: "relative", zIndex: 2 }}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Box
              className="left-section"
              style={{
                marginLeft: width > 1080 ? "150px" : "59px",
              }}
            >
              <div className="typography-heading">
                Start a Fundraiser Today & Make a Difference
              </div>

              <p className="fund-description">
                Launch your campaign in just a few clicks and raise funds for
                your loved ones' medical treatments.
              </p>
              <p className="fund-description">
                Example: Kavya raised ₹ 10,00,000 for her father’s surgery in
                just 15 days through online support!
              </p>
              <p className="fund-description">
                Quick, Easy, and Transparent Fundraising – Start yours today!
              </p>
            </Box>
          </Grid>

          {/* Right Section (Form Section) */}
          <Grid item xs={12} md={6}>
            <Box
              className="form-container"
              style={{
                padding: "20px",
                borderRadius: "15px",
                boxShadow: "0 8px 15px rgba(0, 0, 0, 0.15)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "@media (max-width: 768px)": {
                  padding: "15px", // Reduce padding for mobile
                },
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <form method="post" action="#" onSubmit={handleFormSubmit}>
                <Grid container spacing={3}>
                  {/* Full Name & Mobile Number in One Row */}
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Full Name"
                          name="fullName"
                          required
                          variant="outlined"
                          size="small"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                          }}
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "none", // Removes the default border
                            },
                            "& .MuiOutlinedInput-root": {
                              borderBottom: "2px solid #ccc", // Adds only a bottom border
                              "&:hover": {
                                borderBottom: "2px solid #0b837a", // Optional: Hover effect
                              },
                              "&.Mui-focused": {
                                borderBottom: "2px solid #0b837a", // Optional: Focus effect
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Your Mobile Number"
                          type="tel"
                          name="phoneNo"
                          required
                          variant="outlined"
                          size="small"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                          }}
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "none", // Removes the default border
                            },
                            "& .MuiOutlinedInput-root": {
                              borderBottom: "2px solid #ccc", // Adds only a bottom border
                              "&:hover": {
                                borderBottom: "2px solid #0b837a", // Optional: Hover effect
                              },
                              "&.Mui-focused": {
                                borderBottom: "2px solid #0b837a", // Optional: Focus effect
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Email Address & Company Name in One Row */}
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Email Address"
                          type="email"
                          name="email"
                          required
                          variant="outlined"
                          size="small"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                          }}
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "none", // Removes the default border
                            },
                            "& .MuiOutlinedInput-root": {
                              borderBottom: "2px solid #ccc", // Adds only a bottom border
                              "&:hover": {
                                borderBottom: "2px solid #0b837a", // Optional: Hover effect
                              },
                              "&.Mui-focused": {
                                borderBottom: "2px solid #0b837a", // Optional: Focus effect
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Company Name"
                          type="text"
                          name="companyName"
                          required
                          variant="outlined"
                          size="small"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                          }}
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "none", // Removes the default border
                            },
                            "& .MuiOutlinedInput-root": {
                              borderBottom: "2px solid #ccc", // Adds only a bottom border
                              "&:hover": {
                                borderBottom: "2px solid #0b837a", // Optional: Hover effect
                              },
                              "&.Mui-focused": {
                                borderBottom: "2px solid #0b837a", // Optional: Focus effect
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Turnover & Industry in One Row */}
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Turnover"
                          name="turnover"
                          required
                          variant="outlined"
                          size="small"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                          }}
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "none", // Removes the default border
                            },
                            "& .MuiOutlinedInput-root": {
                              borderBottom: "2px solid #ccc", // Adds only a bottom border
                              "&:hover": {
                                borderBottom: "2px solid #0b837a", // Optional: Hover effect
                              },
                              "&.Mui-focused": {
                                borderBottom: "2px solid #0b837a", // Optional: Focus effect
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Industry"
                          name="industry"
                          required
                          variant="outlined"
                          size="small"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                          }}
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "none", // Removes the default border
                            },
                            "& .MuiOutlinedInput-root": {
                              borderBottom: "2px solid #ccc", // Adds only a bottom border
                              "&:hover": {
                                borderBottom: "2px solid #0b837a", // Optional: Hover effect
                              },
                              "&.Mui-focused": {
                                borderBottom: "2px solid #0b837a", // Optional: Focus effect
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Funding Requirements (Standalone Row) */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Funding Requirements"
                      name="fundingRequirements"
                      required
                      multiline
                      rows={1}
                      variant="outlined"
                      size="small"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                      }}
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        "& fieldset": {
                          border: "none", // Removes the default border
                        },
                        "& .MuiOutlinedInput-root": {
                          borderBottom: "2px solid #ccc", // Adds only a bottom border
                          "&:hover": {
                            borderBottom: "2px solid #0b837a", // Optional: Hover effect
                          },
                          "&.Mui-focused": {
                            borderBottom: "2px solid #0b837a", // Optional: Focus effect
                          },
                        },
                      }}
                    />
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        background: "linear-gradient(90deg, #0b837a, #9ddad5)",
                        color: "#fff",
                        padding: "10px 20px",
                        fontWeight: "bold",
                        borderRadius: "25px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                        transition: "transform 0.3s ease",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                      fullWidth
                    >
                      START A FUNDRAISER
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <div className="fundraisetop">
        <div className="row">
          <div className="col-heading">
            <h1>Fundraising Solutions</h1>
          </div>
          <div className="col-content">
            <h3>Fuel Your Business Growth with the Right Capital</h3>
            <p>
              At AQT Direct, we empower businesses by securing the right funding
              at the right terms. Whether you’re a startup seeking seed capital
              or an established company aiming for expansion, our expert-led
              fundraising solutions ensure you achieve your financial goals
              efficiently.
            </p>
          </div>
        </div>
      </div>

      <div className="fundraising-container">
        <h2 className="header">Our Fundraising Approach</h2>
        <p className="fund-subheading">
          We provide end-to-end support to help businesses achieve their
          financial goals. Our tailored strategies are designed to ensure
          sustainable growth and seamless investor relationships. Let us guide
          you in securing the capital you need to thrive in today's competitive
          market.
        </p>
        {/* 
        <Row
          gutter={[24, 24]}
          justify="center"
          align="middle"
          className="row-spacing"
        >
          <Col xs={24} sm={12} md={10}>
            <img src={strategic} alt="Fundraising" className="image-style" />
          </Col>
          <Col xs={24} sm={12} md={10}>
            <div className="content">
              <h3 className="card-title">Strategic Capital Planning</h3>
              <ul style={{fontSize:'16px'}}>
                <li>
                  We analyze your financial requirements, business model, and
                  expansion goals.
                </li>
                <li>
                  A customized fundraising strategy is developed to align with
                  your long-term vision.
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row
          gutter={[24, 24]}
          justify="center"
          align="middle"
          className="row-spacing"
        >
          <Col xs={24} sm={12} md={10} order={1}>
            <div className="content">
              <h3 className="card-title">Equity & Debt Structuring</h3>
              <ul  style={{fontSize:'16px'}}>
                <li>
                  Get expert guidance on choosing between equity financing and
                  debt funding.
                </li>
                <li>
                  We structure deals that maintain financial stability and
                  growth potential.
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} sm={12} md={10} order={2}>
            <img src={Equity} alt="Fundraising" className="image-style" />
          </Col>
        </Row>

        <Row
          gutter={[24, 24]}
          justify="center"
          align="middle"
          className="row-spacing"
        >
          <Col xs={24} sm={12} md={10}>
            <img src={Investor} alt="Fundraising" className="image-style" />
          </Col>
          <Col xs={24} sm={12} md={10}>
            <div className="content">
              <h3 className="card-title">
                Investor-Ready Financials & Valuation
              </h3>
              <ul style={{fontSize:'16px'}}>
                <li>
                  Accurate business valuations to strengthen investor
                  negotiations.
                </li>
                <li>
                  Financial insights that enhance credibility and
                  decision-making.
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row
          gutter={[24, 24]}
          justify="center"
          align="middle"
          className="row-spacing"
        >
          <Col xs={24} sm={12} md={10} order={1}>
            <div className="content">
              <h3 className="card-title">
                Compelling Investor Pitch Preparation
              </h3>
              <ul style={{fontSize:'16px'}}>
                <li>
                  Professionally crafted pitch decks and financial models.
                </li>
                <li>
                  Data-driven presentations that captivate and convince
                  investors.
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} sm={12} md={10} order={2}>
            <img src={Compelling} alt="Fundraising" className="image-style" />
          </Col>
        </Row> */}

        {/* <Row gutter={[24, 24]} justify="center">
          {services.map((service, index) => (
            <Col xs={24} sm={12} md={10} key={index}>
              <div className="service-card">
                <img
                  src={service.img}
                  alt={service.title}
                  className="image-style"
                />
                <div className="content">
                  <h3 className="card-title">{service.title}</h3>
                  <ul style={{ fontSize: "16px", listStyleType: "none" }}>
                    {service.points.map((point, idx) => (
                      <li key={idx}>{point}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
          ))}
        </Row> */}

<Container>
<Row gutter={[4,45]} justify="center">
  {services.map((service, index) => ( 
    <Col xs={24} sm={12} md={12} lg={12} xl={12} key={index}>
      <div className="service-card">
        <img
          src={service.img}
          alt={service.title}
          className="image-style"
        />
        <div className="content">
          <h3 className=" ">{service.title}</h3>
          <ul>
            {service.points.map((point, idx) => (
              <li key={idx}>{point}</li>
            ))}
          </ul>
        </div>
      
      </div>
    </Col>
  ))}
</Row>
</Container>



      </div>

      {/* Why Choose AQT Direct? Section */}
      <div
        div
        className="features-container"
        style={{ textAlign: "center", padding: "40px 20px" }}
      >
        <h2
          className="features-title"
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            color: "#1e293b",
            paddingBottom: "12px",
          }}
        >
          We Provide Everything You Need{" "}
        </h2>
        {/* <Box
          width={100}
          height={4}
          bgcolor="#0f766e"
          mx="auto"
          mt={1}
          style={{ marginBottom: "30px" }}
        /> */}
        <div
          className="features-list"
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "60px",
            flexWrap: "wrap",
          }}
        >
          {features.map((feature, index) => (
            <div
              key={index}
              className="feature-item"
              style={{
                flex: "1",
                minWidth: "200px",
                maxWidth: "300px",
                textAlign: "center",
              }}
            >
              {getIconByTitle(feature.title)}{" "}
              <h3
                className="feature-title"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#1e293b",
                  margin: "12px 0 8px 0",
                }}
              >
                {" "}
                {feature.title}{" "}
              </h3>
              <p
                className="feature-description"
                style={{
                  fontSize: "14px",
                  color: "#64748b",
                  lineHeight: "1.5",
                  margin: 0,
                }}
              >
                {" "}
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      <motion.section
        className="ready-to-raise"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap", // Allow wrapping for mobile responsiveness
          backgroundColor: "white",
          padding: "15px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          gap: "20px",
        }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <img
          src={image1}
          alt="Fundraising"
          style={{
            width: "100%", // Make the image responsive
            maxWidth: "250px", // Limit max width
            height: "auto",
          }}
        />

        <div style={{ textAlign: "left", maxWidth: "600px", width: "100%" }}>
          <h2
            className="raise-header"
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              color: "#333",
              marginBottom: "20px",
            }}
          >
            Ready to Raise Capital?
          </h2>
          {/* <Box
            width={150}
            height={3}
            bgcolor="#0f766e"
            mt={1}
            style={{ marginBottom: "30px" }}
          /> */}
          <p
            className="raise-description"
            style={{
              fontSize: "16px",
              color: "#666",
              lineHeight: "1.8",
              marginBottom: "20px",
            }}
          >
            Let AQT Direct guide you in securing the right investment for your
            business growth. Contact us today to begin your fundraising journey.
          </p>
        </div>
      </motion.section>

      <Footer />
    </>
  );
}
