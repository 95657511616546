import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { beashark } from "../../components/Api/Api";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Select from "react-select";
import { UserOutlined, ShopOutlined, BankOutlined, LineChartOutlined } from "@ant-design/icons";
import "./SharkForm.css";

import SharkBanner from "../MainPage/MainPageContent/SharkBanner.jsx";
import background from "../../assets/images/contact-background.jpg";
const interestOptions = [
  { value: "Networking", label: "Networking" },
  { value: "Funding", label: "Funding" },
  { value: "Learning", label: "Learning" },
  { value: "Investing", label: "Investing" },
  { value: "Other", label: "Other" },
];

const roleOptions = [
  { value: "Startup Founder", label: "Startup Founder" },
  { value: "Investor", label: "Investor" },
  { value: "Professional", label: "Professional" },
  { value: "Other", label: "Other" },
];



const SharkForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNo: "",
    city: "",
    state: "",
    companyName: "",
    professionIndustry: "",
    role: "",
    roleOther: "",
    isBusinessOwner: "",
    interests: [],
    interestsOther: "",
    businessOwner: "",
    founder: "",
    investmentAmount: "",
  });




  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMultiSelect = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setFormData((prev) => ({ ...prev, interests: values }));
  };

  const handleRoleChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, role: selectedOption.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let finalData = { ...formData };

    // Handle "Other" in role
    if (finalData.role === "Other" && finalData.roleOther) {
      finalData.role = finalData.roleOther;
    }
    delete finalData.roleOther;

    // Handle "Other" in interests
    if (finalData.interests.includes("Other") && finalData.interestsOther) {
      finalData.interests = [finalData.interestsOther];
    }
    delete finalData.interestsOther;

    try {
      const response = await axios.post(beashark, finalData, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("Response:", response.data);
      navigate("/thank-you");
    } catch (error) {
      console.error(
        "Error submitting form:",
        error.response?.data || error.message
      );
      alert("Submission Failed!");
    }
  };

  return (
    <div className="main-shark-form-div">

      <Navbar />
      <SharkBanner />
      <div className="shark-form-container">
        <form onSubmit={handleSubmit} className="shark-form">
          {/* Personal Information */}
          <fieldset className="shark-fieldset shadow">
            <legend><UserOutlined /> Personal Information</legend>
            <div className="shark-input-group">
              <input type="text" name="fullName" placeholder="Full Name" onChange={handleChange} required />
              <input type="email" name="email" placeholder="Email Address" onChange={handleChange} required />
            </div>
            <div className="shark-input-group">
              <input type="tel" name="phoneNo" placeholder="Phone Number" onChange={handleChange} required />
              <input type="text" name="city" placeholder="City" onChange={handleChange} required />
            </div>
          </fieldset>

          {/* Professional Background */}
          <fieldset className="shark-fieldset shadow">
            <legend><ShopOutlined /> Professional Background</legend>
            <input type="text" name="professionIndustry" placeholder="Profession/Industry" onChange={handleChange} required />
            <label>Are you a:</label>
            <Select options={roleOptions} className="shark-select" onChange={handleRoleChange} />
            {formData.role === "Other" && <input type="text" name="roleOther" placeholder="Specify" onChange={handleChange} />}
          </fieldset>

          {/* Business Ownership */}
          <fieldset className="shark-fieldset shadow">
            <legend><BankOutlined /> Business Ownership</legend>
            <label>Do you currently run a business?</label>
            <select name="isBusinessOwner" onChange={handleChange} className="shark-dropdown">
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </fieldset>

          {/* Business & Investment */}
          <fieldset className="shark-fieldset shadow">
            <legend><LineChartOutlined /> Business & Investment</legend>
            <textarea name="businessOwner" placeholder="Sectors you invest in" onChange={handleChange}></textarea>
            <textarea name="founder" placeholder="Funding or partnerships interest" onChange={handleChange}></textarea>
            <input type="text" name="investmentAmount" placeholder="Preferred investment range" onChange={handleChange} />
          </fieldset>

          <button style={{ background: "#14213d", borderRadius: "30px" }} type="submit" className="shark-submit-button">
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default SharkForm;
