
import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import startup1 from "../../assets/images/carousel_one/startup.jpg";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

const StartUp = () => {
  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <Box sx={{ position: "relative", mb: 4 }}>
        <img
          src={startup1}
          alt="aboutus"
          style={{
            width: "100%",
            height: "24rem",
            objectFit: "cover",
            display: "block",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "black",
            textAlign: "center",
            // background: "rgba(0, 0, 0, 0.5)",
            padding: "1rem 2rem",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Fuel Innovation Through Startup Investments
          </Typography>
        </Box>
      </Box>

      {/* Introduction Section */}
      <Container sx={{ py: 4 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
            mb: 3,
          }}
        >
          Investing in startups is not just about capital; it’s about believing
          in the next big idea and helping shape the future. At AQT, we connect
          you with innovative, high-growth ventures across sectors like
          technology, healthcare, and green energy. These industries are at the
          forefront of transformation, creating exponential growth opportunities
          for early investors.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
          }}
        >
          Startups typically require funding to accelerate product development,
          expand operations, and enter new markets. As an investor, you will be
          part of a dynamic ecosystem that supports these growth objectives. We
          offer you access to ventures with robust business models, experienced
          leadership, and a clear path to scalability.
        </Typography>
      </Container>

      {/* Cards Section */}
      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} alignItems="stretch">
          {/* First Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  Why Choose Startups?
                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                    <strong>High Growth Potential:</strong> Early-stage startups
                    have the capacity for rapid expansion, offering the
                    potential for returns that far outpace traditional
                    investments.
                  </li>
                  <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                    <strong>Diverse Opportunities:</strong> With access to
                    innovative sectors such as AI, clean energy, biotech, and
                    more, you can diversify your portfolio into industries
                    driving tomorrow’s economy.
                  </li>
                  <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                    <strong>Influence and Impact:</strong> As an investor in
                    startups, you often have a direct impact on strategic
                    decisions. Your support can help steer a company towards
                    success.
                  </li>
                  <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                    <strong>Exclusive Opportunities:</strong> AQT vets startups
                    rigorously, ensuring that you are exposed to only the most
                    promising ventures in the market.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  Our Process for Startup Investments
                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                    <strong>Market Viability:</strong> We assess the target
                    market, its size, and the startup's position within the
                    industry to ensure scalability.
                  </li>
                  <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                    <strong>Team Expertise:</strong> Strong leadership is
                    critical. We focus on startups led by experienced and
                    visionary founders who are committed to growth.
                  </li>
                  <li style={{ marginBottom: "18px", lineHeight: "1.6" }}>
                    <strong>Financial Model:</strong> We perform a deep dive
                    into the startup’s financials to ensure the business model
                    is sustainable and aligned with industry benchmarks.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default StartUp;
