import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import debentures from "../../assets/images/debenture.jpg";

import "./Common.css";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";


export default function Debentures() {
  return (
    <>
      <Navbar />
      <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
        <img
          style={{
            width: "100%",
            justifyContent: "center",
            margin: "auto",
            display: "block",
            height: "20rem",
          }}
          src={debentures}
          alt="debentures"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          margin: "2rem 0rem 0rem 0rem",
          fontFamily: "Montserrat",
        }}
      >
        Secure, Fixed-Income Investment with Debentures
      </Typography>
      <Container sx={{ py: 4 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
            mb: 3,
          }}
        >
          Debentures are a fixed-income investment that offer stable and
          predictable returns for investors seeking lower-risk opportunities.
          AQT provides debentures in solid businesses requiring short-term
          capital injections for expansion or operational stability.
          Debentures are ideal for investors looking for secure,
          interest-bearing investments without the exposure to market
          volatility seen in equities.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
          }}
        >
          Our debenture offerings give you the chance to invest in
          businesses with strong fundamentals, ensuring that you receive
          stable returns while contributing to their growth.

        </Typography>
      </Container>

      {/* Cards Section */}
      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} alignItems="stretch">
          {/* First Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  Why Debentures?
                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Fixed Returns:</strong>Debentures offer consistent
                    interest payments over a defined period, providing predictable
                    income streams for investors.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Lower Risk Profile: </strong>Unlike equity investments,
                    debentures are lower risk, making them suitable for conservative
                    investors looking to preserve capital.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Priority in Payout: </strong>In the event of
                    liquidation, debenture holders are paid before equity investors,
                    further reducing investment risk.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Flexible Terms: </strong>We offer debentures with
                    varying terms and rates, allowing investors to choose the option
                    that best fits their financial goals.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  AQT's Debenture Offerings

                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Business Security: </strong>We focus on companies with
                    solid financials and growth prospects that require temporary
                    capital boosts for projects or operational needs.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Competitive Interest Rates: </strong>Our debenture
                    offerings come with competitive interest rates, designed to
                    provide you with superior returns compared to traditional
                    fixed-income investments.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Transparent Terms: </strong>We ensure that the terms of
                    each debenture are clear, transparent, and fair to investors,
                    providing peace of mind with every transaction.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
