import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Container, Grid, Button, Box } from "@mui/material";
import { FaRupeeSign, FaHandHoldingHeart, FaClock, FaSadTear } from "react-icons/fa";
import { motion } from "framer-motion";
import "./FundRaise.css";
import "./BuySell.css";
import startup1 from "../../assets/images/carousel_one/startup.jpg";



export default function BuySell() {
  const [width, setWidth] = useState(window.innerWidth);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const renderCard = (title, content, index) => (
    <Grid item xs={12} md={6} lg={3} key={index}>
      <motion.div
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ delay: index * 0.2, duration: 0.5 }}
      >
        <Card className="approach-card" style={{ height: '100%' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2">{content}</Typography>
          </CardContent>
        </Card>
      </motion.div>
    </Grid>
  );

  const cardData = [
    {
      title: "High Cost Of Treatment",
      content:
        "Medical emergencies come without a warning. At times, health insurance and savings are not enough to cover the expensive bill. Crowdfunding helps you reach your goal by getting small donations from a large group of people.",
      icon: <FaRupeeSign size={40} color="#29B479" />,
    },
    {
      title: "Asking For Money Isn’t Easy",
      content:
        "When in need, asking for help from people and describing financial problems face-to-face can be awkward and inconvenient. Online crowdfunding makes the process of asking for money easier and way more seamless.",
      icon: <FaHandHoldingHeart size={40} color="#29B479" />,
    },
    {
      title: "Emergencies Demand A Prompt Response",
      content:
        "When your loved ones are diagnosed with life-threatening diseases, it’s overwhelming emotionally as well as financially. Most medical emergencies require the treatment to start at the earliest. By giving you a platform to share your emergency, online crowdfunding helps you gather funds quickly.",
      icon: <FaClock size={40} color="#29B479" />,
    },
    {
      title: "Loan Repayment Is Stressful",
      content:
        "Getting drowned in personal loans is very common in times of medical emergencies. Once the money is gathered, repaying that loan may take months, sometimes years together. Online crowdfunding comes without debts.",
      icon: <FaSadTear size={40} color="#26B07F" />,
    },
  ];

  const treatments = [
    { icon: "", title: "Vast Industry Network", description: "Access a curated pool of serious buyers and sellers." },
    { icon: "", title: "Confidential & Secure Transactions", description: "Your business information remains private and protected." },
    { icon: "", title: "End-to-End Assistance", description: "From valuation to closing, we manage the entire process for a hassle-free experience." },
  ];
  const items = [
    {
      icon: <FaRupeeSign size={30} color="#26B07F" />,
      title: "High Cost Of Treatment",
      description:
        "Medical emergencies come without a warning. At times, health insurance and savings are not enough to cover the expensive bill. Crowdfunding helps you reach your goal by getting small donations from a large group of people.",
    },
    {
      icon: <FaHandHoldingHeart size={30} color="#26B07F" />,
      title: "Asking For Money Isn’t Easy",
      description:
        "When in need, asking for help from people and describing financial problems face-to-face can be awkward and inconvenient. Online crowdfunding makes the process of asking for money easier and way more seamless.",
    },
    {
      icon: <FaClock size={30} color="#26B07F" />,
      title: "Emergencies Demand A Prompt Response",
      description:
        "When your loved ones are diagnosed with life-threatening diseases, it’s overwhelming emotionally as well as financially. Most medical emergencies require the treatment to start at the earliest. By giving you a platform to share your emergency, online crowdfunding helps you gather funds quickly.",
    },
    {
      icon: <FaSadTear size={30} color="#26B07F" />,
      title: "Loan Repayment Is Stressful",
      description:
        "Getting drowned in personal loans is very common in times of medical emergencies. Once the money is gathered, repaying that loan may take months, sometimes years together. Online crowdfunding comes without debts.",
    },
  ];

  return (
    <>
      <Navbar />

          {/* Hero Section */}
            <Box sx={{ position: "relative", mb: 4 }}>
              <img
                src={startup1}
                alt="aboutus"
                style={{
                  width: "100%",
                  height: "24rem",
                  objectFit: "cover",
                  display: "block",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                  textAlign: "center",
                  // background: "rgba(0, 0, 0, 0.5)",
                  padding: "1rem 2rem",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h4" fontWeight="bold">
                Seamless Business Buying & Selling Solutions                </Typography>
              </Box>
            </Box>
            <Container sx={{ py: 4 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
            mb: 3,
          }}
        >

            Whether you’re looking to sell your business or acquire a new one, AQT Direct provides
            expert guidance at every step. Our structured approach ensures smooth transactions,
            secure negotiations, and maximum value for both buyers and sellers.
         
        </Typography>
     
      </Container>
        

      {/* Hero Section */}
      {/* <div className="hero-section">
        <div className="hero-overlay">
       
          <p style={{ marginBottom: "0px" }}>
            Whether you’re looking to sell your business or acquire a new one, AQT Direct provides
            expert guidance at every step. Our structured approach ensures smooth transactions,
            secure negotiations, and maximum value for both buyers and sellers.
          </p>
        </div>
      </div> */}


      <div style={{ padding: "50px 20px", textAlign: "center" }}>
        {/* Heading Section */}
        <div style={{ marginBottom: "40px" }}>
          <h2 style={{ maxWidth: "700px", margin: "0 auto", lineHeight: "1.5" }}>
            Our Business Buying & Selling <br /> Approach
          </h2>
          <div style={{ width: "80px", height: "4px", backgroundColor: "#26B07F", margin: "10px auto" }}></div>
        </div>

        {/* Content Section */}
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "20px" }}>
          {items.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
                maxWidth: "500px",
                textAlign: "left",
                padding: "15px",
                borderRadius: "8px",
              }}
            >
              <div style={{ marginRight: "15px" }}>{item.icon}</div>
              <div>
                <h3 style={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: "bold" }}>{item.title}</h3>
                <p style={{ marginTop: "5px", color: "#666", fontFamily: "Montserrat", lineHeight: "1.6" }}>
                  {item.description}
                </p>

              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="container">
        <h2 className="heading">Why Choose AQT Direct?</h2>
        <div className="underline" />
        <div className="cards-container">
          {treatments.map((treatment, index) => (
            <Card key={index} className="why-card">
              <div className="icon">{treatment.icon}</div>
              <h3>{treatment.title}</h3>
              <p>{treatment.description}</p>
            </Card>
          ))}
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="section cta-section">
        <Container>
          <h2>Ready to Buy or Sell a Business</h2>
          <div className="line-business" />
          <Typography variant="body1" style={{ marginBottom: "20px" }}>
            Let AQT Direct guide you through a seamless and successful transaction. Contact us today to take the next step toward your business goals.
          </Typography>

        </Container>
      </div>

      <Footer />
    </>
  );
}