// import React from "react";
// import "./navbar.css";
// import search from "../../assets/images/search.jpg";
// import { Link, Outlet } from "react-router-dom";
// import aqt from "../../assets/images/aqt.png";
// import Button from '@mui/material/Button';
// import {redirect} from "../Api/Api"

// export default function Another() {
//   return (
//     <>
//       <nav className="nav">
//         <div className="tophalf">
//           <Link to="/">
//             <img
//               style={{
//                 width: "8em",
//                 padding: "1em 0em 0em 1em",
//                 // margin: "1em 0em 0em 1em",
//               }}
//               src={aqt}
//               alt="something"
//             />
//           </Link>

//           <ul className="bottomhalf">
//             <li className="list">
//               <Link className="anc" to="/">
//                 Home
//               </Link>
//             </li>
//             <li className="list">
//               <Link className="anc" to="/about">
//                 About Us
//               </Link>
//             </li>
//             {/* <li className="list">
//               <Link className="anc" to="/limited-partnerships">
//                 Limited Partnerships
//               </Link>
//             </li> */}
//             {/* <li className='list'><a className='anc' href='/drhp'>DRHP-FILED</a></li> */}
//             {/* <li className="list">
//               <Link className="anc" to="/business-opportunities">
//                 Business Opportunities
//               </Link>
//             </li> */}
//             {/* <li className='list'><a className='anc' href='/screener'>Screener</a></li> */}
//             {/* <li className="list">
//               <Link className="anc" to="/insights">
//                 Insights
//               </Link>
//             </li> */}
//             <li className="list">
//               <Link className="anc" to="/peinvestor">
//                 PE Investor
//               </Link>
//               <div className="dropdown">
//                 <Link className="dropdown-item" to="/startUp">Startups</Link>
//                 <Link className="dropdown-item" to="/business">Businesses</Link>
//                 <Link className="dropdown-item" to="/earlyBusiness">Early Stage Business</Link>
//                 <Link className="dropdown-item" to="/debentures">Debentures</Link>
//                 <Link className="dropdown-item" to="/projectInvestment">Project Investment</Link>
//               </div>
//             </li>
//             <li className="list">
//               <Link className="anc" to="/fundraise">
//                 Fund Raising
//               </Link>
//             </li>
//             <li className="list">
//               <Link className="anc" to="/smeloans">
//                 SME Loans
//               </Link>
//             </li>
//             <li className="list">
//               <Link className="anc" to="/buysell">
//               Business For Sell
//               </Link>
//             </li>
//             {/* <li className='list'><a className='anc' href='/raisefunding'>Raise Funding</a></li> */}
//             <li className="list">
//               <Link className="anc" to="/contactus">
//                 Contact Us
//               </Link>
//             </li>
//           </ul>

//           <div className="lowerhalf">
//             <Link to={redirect}>
//             <Button variant="contained" className="login-btn"  style={{backgroundColor:'#35A848'}}>
//               Login
//             </Button></Link>
//           </div>
//         </div>
//       </nav>
//       {/* <div className="linenav">
//     <hr/>
//     </div> */}
//     </>
//   );
// }


import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import aqt from "../../assets/images/aqt.png";
import { redirect } from "../Api/Api"

export default function Another() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleHoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleHoverClose = () => {
    setMenuOpen(false);
  };

  const menuItems = [
    // { text: "Home", link: "/" },
    // { text: "About Us", link: "/about" },
    { text: "Be a Shark", link: '/be-a-shark' },
    {
      text: "PE Investor",
      link: "/peinvestor",
      subMenu: [
        { text: "Startups", link: "/startUp" },
        { text: "Businesses", link: "/business" },
        { text: "Early Stage Business", link: "/earlyBusiness" },
        { text: "Debentures", link: "/debentures" },
        { text: "Project Investment", link: "/projectInvestment" },
      ],
    },
    { text: "Fund Raising", link: "/fundraise" },
    { text: "SME Loans", link: "/smeloans" },
    { text: "Business For Sell", link: "/buysell" },
    { text: "Blogs", link: "/blogs" },

    // { text: "Contact Us", link: "/contactus" },


  ];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
        <Container>
          <Toolbar>
            {/* Logo */}
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                src={aqt}
                alt="Logo"
                style={{ width: "8em", padding: "0.5em 0" }}
              />
            </Link>

            {/* Mobile Menu Icon */}
            <IconButton
              edge="start"
              color="black"
              aria-label="menu"
              sx={{ ml: "auto", display: { sm: "none" } }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>

            {/* Desktop Links */}
            <Box sx={{ display: { xs: "none", sm: "flex" }, ml: "auto" }}>
              {menuItems.map((item) =>
                item.subMenu ? (
                  <Box
                    key={item.text}
                    onMouseEnter={handleHoverOpen}
                    onMouseLeave={handleHoverClose}
                    sx={{ position: "relative" }}
                  >
                    <Button color="inherit"><span style={{ color: 'black' }}>{item.text}</span></Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleHoverClose}
                      MenuListProps={{
                        onMouseEnter: () => setMenuOpen(true),
                        onMouseLeave: handleHoverClose,
                      }}
                    >
                      {item.subMenu.map((subItem) => (
                        <MenuItem
                          key={subItem.text}
                          component={Link}
                          to={subItem.link}
                          onClick={handleHoverClose}
                        >
                          {subItem.text}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                ) : (
                  <Link
                    key={item.text}
                    to={item.link}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "0 0.5em",
                    }}
                  >
                    <Button color="inherit">{item.text}</Button>
                  </Link>
                )
              )}
              {/* <Link to={redirect} style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFFDF0",
                  color: "#35A848",
                  marginLeft: "1em",
                }}
              >
                Login                                                                                                                                   
              </Button>
            </Link> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Drawer for Mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) =>
              item.subMenu ? (
                <React.Fragment key={item.text}>
                  <ListItemButton>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                  {item.subMenu.map((subItem) => (
                    <ListItemButton
                      key={subItem.text}
                      component={Link}
                      to={subItem.link}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary={subItem.text} />
                    </ListItemButton>
                  ))}
                </React.Fragment>
              ) : (
                <ListItemButton key={item.text} component={Link} to={item.link}>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              )
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
}
