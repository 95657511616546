// Contact.jsx
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Typography,
  Container,
  Button,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Navbar from "../../components/navbar/index.jsx";
import Footer from "../../components/Footer/Footer.js";
import contactBanner from "../../assets/images/contact1.jpg";
import { contactapi } from "../../components/Api/Api.jsx";
import "./Contact.css";

export default function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(contactapi, formData, {
        headers: { "Content-Type": "application/json" },
      });
      toast.success(response.data.message);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
      navigate("/thank-you");
    } catch (error) {
      toast.error(error?.message || "An error occurred");
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <div className="main-div">
        <Box className="contact-banner">
          {/* <Typography className="contact-title2">Contact Us</Typography> */}
        </Box>
        <Container className="contact-main-container">
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" className="contact-heading">
                Get in Touch
              </Typography>
              <Typography className="contact-description">
                Whether you have a question about our services, pricing, or
                anything else, our team is ready to answer all your questions.
                Use the form to contact us or reach out directly via email or
                phone.
              </Typography>
              <Box className="contact-info">
                {/* <Typography variant="h6">📞 Phone: +1 (123) 456-7890</Typography> */}
                <Typography variant="h6">
                  📧 <strong>Email: &nbsp;</strong> info@aqtdirect.com
                </Typography>
                <Typography variant="h6">
                  📍 <strong> Address 1: &nbsp;</strong> Enam Sambhav, G Block,
                  Bandra Kurla Complex, Mumbai, Maharashtra 400051
                </Typography>
                <Typography variant="h6">
                  📍<strong> Address 2 : &nbsp;</strong> Unit 911,9th Floor,
                  Iconic Tower, The Corenthum, Sector 62, Noida, G.B Nagar, UP
                  201309, India
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="form-container">
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Your Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        multiline
                        rows={3}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
} 