
import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import startup1 from "../../assets/images/carousel_one/earlybusiness.jpg";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

const StartUp = () => {
  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <Box sx={{ position: "relative", mb: 4 }}>
        <img
          src={startup1}
          alt="aboutus"
          style={{
            width: "100%",
            height: "24rem",
            objectFit: "cover",
            display: "block",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            textAlign: "center",
            // background: "rgba(0, 0, 0, 0.5)",
            padding: "1rem 2rem",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Shape the Future of High-Growth Ventures

          </Typography>
        </Box>
      </Box>

      {/* Introduction Section */}
      <Container sx={{ py: 4 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
            mb: 3,
          }}
        >
          Early-stage businesses represent the next phase in a company’s development—often beyond the startup phase but not yet mature. These ventures are typically in their growth stage, requiring capital to scale operations, enter new markets, or refine their products. Investing in early-stage companies gives you the opportunity to be a critical part of a company’s upward trajectory.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
          }}
        >
          At AQT, we identify businesses with strong fundamentals and growth potential, allowing investors to get in on the ground floor of what could be the next industry leader.

        </Typography>
      </Container>

      {/* Cards Section */}
      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} alignItems="stretch">
          {/* First Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  Why Early-Stage Businesses?
                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Growth Phase:</strong>Early-stage businesses are past the initial risk phase but still have significant growth potential, offering a balanced risk-reward profile.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Influence in Strategy:</strong> Investors often have a say in the company’s strategic direction, allowing them to shape how the business scales.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>High-Return Potential:</strong> While riskier than investing in established businesses, early-stage companies offer the chance for substantial returns as they grow.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Sector-Focused Opportunities:</strong> AQT connects investors with early-stage businesses across multiple sectors, including fintech, e-commerce, healthcare, and renewable energy.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  Our Approach to Early-Stage Investments

                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Growth Strategy:</strong> We assess each business’s potential for scaling and market penetration, focusing on those with clear expansion strategies.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Management Team:</strong> Leadership is key. We look for founders and teams with a track record of success and the vision to lead the company to the next level.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Capital Requirements: </strong> We ensure the business has the right capital structure to scale effectively and manage operational growth without overextending resources.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default StartUp;
