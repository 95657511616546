import React from "react";
import { Carousel } from "antd";
import { Card } from "antd";
import "./CarouselTwo.css"; // Add custom styles here

// Import images
import AcquisitionFunding from "../../../assets/images/busnies-loan.jpeg";
import AngelInvestment from "../../../assets/images/working.jpeg";
import CorporateFinance from "../../../assets/images/machinery-loan.jpeg";
import PrivateEquityFunding from "../../../assets/images/carousel_one/Private Equity Funding .png";
import ProjectFinance from "../../../assets/images/Mudra-Loan.jpeg";
import PromoterFunding from "../../../assets/images/Secure.jpeg";
import SMELoans from "../../../assets/images/carousel_one/SME Loans.png";

// Data array
const cardData = [

    { img: AngelInvestment, title: "Working Capital" },
    { img: CorporateFinance, title: "Machinery Loan" },
    { img: AcquisitionFunding, title: "Business Loan" },
    { img: PrivateEquityFunding, title: "O.D / C.C " },
    { img: ProjectFinance, title: "Mudra Loan" },
    { img: PromoterFunding, title: "Secure Loan - BL" },
];

const CarouselTwo = () => {
    return (
        <div className="carousel-container">
            <div className="additional-content">
                <h4>From Nationalized banks</h4>

            </div>
            <Carousel
                autoplay
                dots={false}
                slidesToShow={6}
                responsive={[
                    { breakpoint: 1200, settings: { slidesToShow: 4 } },
                    { breakpoint: 992, settings: { slidesToShow: 3 } },
                    { breakpoint: 768, settings: { slidesToShow: 2 } },
                    { breakpoint: 576, settings: { slidesToShow: 2 } }, // Show 2 cards on mobile
                ]}
            >
                {cardData.map((card, index) => (
                    <div key={index} className="carousel-card">
                        <Card className="card-style">
                            <div className="card-content">
                                <img src={card.img} alt={card.title} className="card-img" />
                                <h4 className="card-title">{card.title}</h4>
                            </div>
                        </Card>
                    </div>
                ))}
            </Carousel>

        </div>
    );
};

export default CarouselTwo;
