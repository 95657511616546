import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import projectInvestment from "../../assets/images/projectinvestment.png";

import "./Common.css";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

export default function ProjectInvestment() {
  return (
    <>
      <Navbar />
      <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
        <img
          style={{
            width: "100%",
            justifyContent: "center",
            margin: "auto",
            display: "block",
            height: "20rem",
          }}
          src={projectInvestment}
          alt="projectInvestment"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          margin: "2rem 0rem 2rem 0rem",
          fontFamily: "Montserrat",
          marginTop: "12px",
        }}
      >
        Invest in Large-Scale Projects with Lasting Impact
      </Typography>

      <Container sx={{ py: 4 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            color: "#555",
            textAlign: "justify",
            mb: 3,
          }}
        >
          AQT connects investors with large-scale, high-impact projects across
          industries such as infrastructure, renewable energy, and technology.
          Project investments not only offer significant financial returns but
          also contribute to societal and economic development. Whether it’s a
          green energy initiative or a major infrastructure project, these
          investments allow you to diversify your portfolio with projects that
          are both profitable and purpose-driven.
        </Typography>

      </Container>


      {/* Cards Section */}
      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} alignItems="stretch">
          {/* First Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  Why Project Investments?
                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Long-Term Growth: </strong>Large-scale projects are
                    typically structured for long-term profitability, offering
                    sustained returns over an extended period.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>High Impact: </strong>These investments contribute to
                    societal progress, including infrastructure development,
                    renewable energy solutions, and technological advancements.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Diversified Portfolio: </strong>Project investments
                    allow you to diversify beyond traditional equity or debt, adding
                    a unique asset class to your portfolio.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Risk-Adjusted Returns: </strong>While larger in scale,
                    these projects are carefully vetted to balance risk and return,
                    ensuring that you can achieve strong performance over time.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardContent style={{ fontFamily: "Montserrat", }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom style={{ marginBottom: "15px" }}>
                  Our Project Portfolio

                </Typography>
                <ul style={{ paddingLeft: "1rem", color: "#555" }}>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Infrastructure: </strong>Invest in the development of
                    critical infrastructure projects that support economic growth
                    and urban development.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Renewable Energy: </strong>Participate in clean energy
                    projects, including solar, wind, and hydroelectric initiatives,
                    that contribute to a sustainable future.
                  </li>
                  <li style={{ marginBottom: "15px", lineHeight: "1.6" }}>
                    <strong>Technology Parks: </strong>We offer investment
                    opportunities in large technology parks that drive innovation
                    and job creation across regions.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
