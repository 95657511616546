import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import loan from "../../assets/images/loan.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import "./FundRaise.css";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Carousel } from "antd";
import "antd/dist/reset.css";
import CarouselTwo from "../MainPage/MainPageContent/CarouselTwo.jsx";

export default function SMELoans() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Navbar />
      {/* Slider Div */}
      <CarouselTwo />

      <div className="mediacover1">
        <Grid
          container
          spacing={2}
          style={{ marginTop: "1px", padding: isMobile ? "16px" : "32px" }}
        >
          <Grid item xs={12} md={6}>
            <div className="mediacover11">
              <Typography
                variant={isMobile ? "h4" : "h4"}

                style={{ fontWeight: "bold", textAlign: isMobile ? "center" : "left" }}
              >
                Tailored Financial Solutions for SMEs
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "justify",
                  marginTop: "16px",
                  fontSize: isMobile ? "14px" : "16px",
                }}
              >
                Small and medium-sized enterprises are critical drivers of the
                economy, and we understand the importance of access to flexible,
                reliable financing. At AQT, we offer a range of loan options
                specifically designed to meet the needs of SMEs, whether it’s
                for expansion, working capital, or new projects.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: isMobile ? "0" : "0 168px 0 0" }}>
            <img
              src={loan}
              style={{ width: "100%", borderRadius: "12px" }}
              alt="articalimage"
            />
          </Grid>
        </Grid>
      </div>

      <Container
        style={{
          marginTop: "36px",
          marginBottom: "36px",
          padding: isMobile ? "16px" : "0",
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          align="center"
          style={{
            fontWeight: "bold",
            marginBottom: "36px",
          }}
        >
          Our SME Loan Services Include
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {[
            {
              title: "Competitive Rates",
              description:
                "We offer some of the most competitive interest rates in the market, helping you secure the financing you need without putting undue pressure on your cash flow.",
            },
            {
              title: "Flexible Repayment Terms",
              description:
                "We understand the challenges that SMEs face, so we offer flexible repayment terms that align with your cash flow cycles.",
            },
            {
              title: "Quick Approval Process",
              description:
                "Our streamlined loan approval process ensures that you get access to funds when you need them most.",
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                }}
              >
                <CardActionArea
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "12px",
                        fontSize: isMobile ? "18px" : "20px",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: "#555",
                        fontSize: isMobile ? "13px" : "14px",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Footer />
    </>
  );
}