"use client";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./NewBlogs.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Container from "@mui/material/Container";

const NewBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get(
          "https://taiikitalks.com/api/get-posts/aqt"
        );
        setBlogs(res.data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);


  return (
    <>
      <Navbar />
      <Container>
        <div className="new-blogs-container">
          <h1 className="new-blogs-title">Latest Blogs</h1>
          {loading ? (
            <p className="loading-text">Loading blogs...</p>
          ) : blogs.length === 0 ? (
            <p className="no-blogs-text">No blogs found</p>
          ) : (
            <div className="new-blogs-grid">
              {blogs.map((blog) => (
                <div key={blog.id} className="new-blogs-card">
                  <img
                    src={`https://taiikitalks.com/${blog.image_mid}`}
                    alt={blog.title}
                    className="new-blogs-image"
                  />
                  <h2 className="new-blogs-heading">{blog.title}</h2>
                  <p className="new-blogs-summary">{blog.summary}</p>
                  <div className="read-more-container">
                    <button
                      onClick={() => navigate(`/blog-detail/${blog.id}`)}
                      className="new-blogs-button"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default NewBlogs;
