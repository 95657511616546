// 'use client';
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import './BlogDetails.css';
// import Footer from "../../components/Footer/Footer";
// import Navbar from "../../components/navbar/index.jsx";

// const BlogDetails = () => {
//     console.log('inside blog detail')
//   const { id } = useParams();
//   const [blog, setBlog] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlog = async () => {
//       try {
//         const res = await axios.get('https://taiikitalks.com/api/get-posts/hbfdirect');
//         const blogData = res.data.data.find((post) => post.id === id);
//         setBlog(blogData);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching blog details:', error);
//         setLoading(false);
//       }
//     };
//     fetchBlog();
//   }, [id]);

//   if (loading) return <p className="blog-loading">Loading...</p>;
//   if (!blog) return <p className="blog-error">Blog not found.</p>;

//   return (
//     <>
//        <Navbar />

//     <div className="blog-details-container">
//       <h1 className="blog-title">{blog.title}</h1>
//       <img src={`https://taiikitalks.com/${blog.image_big}`} alt={blog.title} className="blog-image" />
//       <p className="blog-summary">{blog.summary}</p>
//       <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
//     </div>
//     <Footer />
//     </>
//   );
// };

// export default BlogDetails;

'use client';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './BlogDetails.css';
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get('https://taiikitalks.com/api/get-posts/aqt');
        setBlogs(res.data.data);
        const blogData = res.data.data.find((post) => post.id === id);
        setBlog(blogData);
      } catch (error) {
        console.error('Error fetching blog details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, [id]);

  if (loading) return <p className="blog-loading">Loading...</p>;
  if (!blog) return <p className="blog-error">Blog not found.</p>;

  return (
    <>
        <Navbar />
    <div className="blog-details-wrapper">
      <div className="blog-details-container">
        <h1 className="blog-title">{blog.title}</h1>
        <img src={`https://taiikitalks.com/${blog.image_big}`} alt={blog.title} className="blog-image" />
        <p className="blog-summary">{blog.summary}</p>
        <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
      </div>

      <aside className="blog-sidebar">
        <h2 className="sidebar-title">Other Blogs</h2>
        <ul className="blog-list">
          {blogs.map((item) => (
            <li key={item.id} className="blog-list-item">
              <button onClick={() => navigate(`/blog-detail/${item.id}`)} className="blog-list-button">
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      </aside>
    </div>
       <Footer />
     </>
  );
};

export default BlogDetails;
