import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Card = styled.div`
  text-align: center;
  background-color: white;
  border-radius: 20px;
  padding: 50px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  animation: ${fadeIn} 0.8s ease-out;
`;

const Icon = styled(FaCheckCircle)`
  font-size: 5rem;
  color: #4caf50;
  margin-bottom: 20px;
  animation: ${pulse} 1.5s infinite;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
`;

const Button = styled.button`
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, #4caf50, #81c784);
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
`;

const ThankYou = () => {
  const navigate = useNavigate();
  
  
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (!urlParams.get("reloaded")) {
      urlParams.set("reloaded", "true");
      window.location.replace(`${location.pathname}?${urlParams.toString()}`);
    }
  }, [location]);

  

  return (
    <Container>
      <Card>
        <Icon />
        <Title>Thank You!</Title>
        <Message>Your submission has been received successfully.</Message>
        <Button onClick={() => navigate("/")}>Go to Home</Button>
      </Card>
    </Container>
  );
};

export default ThankYou;