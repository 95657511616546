import React from "react";
import "./SharkBanner.css"; // Add styling for animations

const SharkBanner = () => {
  return (
    <div className="shark-banner">
      <h1 className="shark-text">BE A SHARK</h1>
    </div>
  );
};

export default SharkBanner;
